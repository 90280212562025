import React, { useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import StoreContext from '../../context/store-context';

const ProductForm = ({ product }) => {
  // We arent' using multiple variants (like t-shirt sizes), and so the product is always the first 'variant'
  // Revisit if t-shirts get added, but we don't wanna hav ea drop down that always shows one item.
  const {
    variants: [variant]
  } = product;
  const {
    addVariantToCart,
    store: { client, adding, checkout }
  } = useContext(StoreContext);

  const productVariant =
        client.product.helpers.variantForOptions(product, variant) || variant;
  const [available, setAvailable] = useState(productVariant.availableForSale);
  const [quantityInCart, setQuantityInCart] = useState(0);

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        );
        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [client.product, productVariant.shopifyId]
  );

  useEffect(() => {
    if (checkout.lineItems.length > 0) {
      const itemInCart = checkout.lineItems.find(item => item.variant.attrs.id.value === productVariant.shopifyId);
      const itemQuantity = (itemInCart && itemInCart.quantity)
        ? itemInCart.quantity
        : 0;
      setQuantityInCart(itemQuantity);
    }
  }, [checkout, productVariant.shopifyId, quantityInCart]);

  useEffect(() => {
    checkAvailability(product.shopifyId);
  }, [productVariant, checkAvailability, product.shopifyId]);

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, 1);
  };

  return (
    <>
      {available &&
        <button
          className='text-xl border-black border-solid border-2 p-2 my-4 h-16'
          type='submit'
          disabled={!available || adding}
          onClick={handleAddToCart}
        >
    Add to Cart
    </button>}
    {quantityInCart > 0 &&
        <p>{quantityInCart} added to cart!</p> }
    {!available && <p>This Product is out of Stock!</p>}
    </>
  );
};

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string
      })
    )
  }),
  addVariantToCart: PropTypes.func
};

export default ProductForm;
