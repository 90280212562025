import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ProductForm from '../../components/shop/product-form.js';
import {
  tail
} from 'lodash';

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct;
  const price = product.variants[0].price;
  const mainImage = product.images[0];
  const restImages = tail(product.images);

  return (
    <section className='flex flex-wrap my-8 m-auto'>
      <figure className='w-full md:w-1/2'>
        <Img
          className='w-100'
          fluid={mainImage.localFile.childImageSharp.fluid}
          key={mainImage.id}
          alt={product.title}
        />
      </figure>
      <div className='w-full md:w-1/2 py-4 md:px-8 md:py-0'>
        <h2 className='font-semibold text-lg'>{product.title}</h2>
        <h2 className='text-lg italic'>${price}</h2>
        <ProductForm product={product} />
      </div>
      <div
        className='w-full py-8'
        dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
      />
      {restImages.map(image => (
        <figure
          key={image.id}
          className='w-full md:w-1/2 p-2 flex justify-center items-center'
        >
          <Img
            className='w-full'
            fluid={image.localFile.childImageSharp.fluid}
            alt={product.title}
          />
        </figure>
      ))}
    </section>
  );
};

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default ProductPage;
